<template>
  <div id="checkinActive" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <v-card id="dialog" class="TF type2" width="100%">
        <div class="b-content">
          <div class="B-dialogform step">
            <div class="t-namepage" style="text-align: center">
              <b>Signature</b>
            </div>
            <div class="b-form">
              <!-- Sign Signature -->
              <div id="personSignature" class="wrap-Main">
                <div class="box-S4">
                  <div class="B-carddetail B-formMain">
                    <div class="b-signature">
                      <div class="b-sign">
                        <!-- ใส่ Script Sign -->
                        <VueSignaturePad width="100%" height="250px" ref="signaturePad" :options="options" />
                      </div>
                    </div>
                    <!-- <div class="center" v-if="this.checkbox1 == true && this.checkbox2 == true"> -->
                    <!-- <div class="box-S4 flex-between-center" v-if="this.checkbox1 == true && this.checkbox2 == true"> -->
                    <div class="box-S4 flex-between-center">
                      <div class="b-btnSave">
                        <v-btn class="theme-btn-even btn-cancle Bsize150" @click="UndoSignature()">
                          <span class="T-size-18">Clear</span>
                        </v-btn>
                        <v-btn class="theme-btn-even btn-sign Bsize150" @click="SaveSignatureToDB()">
                          <span class="I-sign2"></span>
                          <span class="T-size-18">Sign</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Sign Signature -->

                <!--Show Img Signature -->
                <div class="box-S4" v-show="false">
                  <div class="B-carddetail B-formMain">
                    <div class="b-condition">
                      <!-- <v-img
              class="center"
              max-width="750px"
              :src="ArrDataPassenger.p_checkin_signature"
            ></v-img> -->
                    </div>
                    <div style="text-align: center">
                      <div>CheckIn<v-icon>mdi-check</v-icon>
                      </div>
                      <div class="flex-center">CheckIn Date : -</div>
                    </div>

                    <div style="text-align: center; padding-top: 20px">
                      <v-btn class="theme-btn-even btn-save Bsize150">
                        <!-- <span class="I-save"></span> -->
                        <span class="T-size-18">Print</span>
                      </v-btn>
                    </div>
                  </div>
                </div>

                <div class="line"></div>
              </div>
              <!--Show Img Signature -->
            </div>
          </div>
        </div>
      </v-card>

      <v-dialog v-model="dialogWarningSign" persistent max-width="300">
        <v-card id="dialog" class="TF type1">
          <div class="b-content">
            <div class="B-dialog warning3">
              <div class="t-title">Warning</div>
              <div class="t-des">
                <div>Please sign your name.</div>
              </div>
              <div class="box-S4"></div>
            </div>
            <div class="box-btn">
              <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialogWarningSign = false">
                <span class="T-size-18">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogSaveComplete" persistent max-width="300">
        <v-card id="dialog" class="TF type1">
          <div class="b-content">
            <div class="B-dialog confirm">
              <div class="t-title">Save Completed</div>
            </div>
            <div class="box-btn">
              <v-btn class="theme-btn-even btn-ok Bsize100" style="width: 100%;" text :to="{
                name: 'MubadalaForCustomerEFormThankYou'
              }">
                <span class="T-size-18">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import uploadBlob from "../utils/uploadBlob";

export default {
  data: () => ({
    checkbox1: false,
    options: {
      penColor: "#00f",
      minWidth:3,
      maxWidth:3,
    },
    idPass: null,
    dialogSaveComplete: false,
    user:{},

    dialogWarningSign:false,
  }),

  components: {},
  async mounted() {
    this.idPass = this.$route.params.id;
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    let q = {};
    q.p_id = this.$route.params.id;
    let res = await feathersClientUOA
      .service("viewcheckinpassenger-2")
      .find({ query: q });
    let user = res.data[0];
    this.user = user
    if(user.maf_status == 'Wait' && user.maf_status == 'No Data')
    {
      this.$router.push({
          path: `/MubadalaForCustomerEFormSignature/${this.$route.params.id}`,
        });
    }
    else if(user.mcf_status == 'Wait'&& user.mcf_status == 'No Data')
    {
      this.$router.push({
          path: `/mubadalaforcustomereform3/${this.$route.params.id}`,
        });
    }
    else if(user.mhf_status == 'Wait'&& user.mhf_status == 'No Data')
    {
      this.$router.push({
          path: `/mubadalaforcustomereform2/${this.$route.params.id}`,
        });
    }
    else if (user.mcf_pass_signature != null && user.mhf_pass_singnature != null && user.maf_pass_signature )
    {
      this.$router.push({
          path: `/mubadalaforcustomereformthankyou`,
        });
    }
  },
  methods: {
    async UndoSignature() {
      this.$refs.signaturePad.undoSignature();
    },
    async SaveSignatureToDB() {
      const dataURL = this.$refs.signaturePad.saveSignature();
      if(dataURL.isEmpty != true) {
        const resSignature = await uploadBlob(dataURL.data);
        try {
          let q1 = {};
          q1.p_id = this.idPass;
          let res1 = await feathersClientUOA
            .service("mubadala-health-form-2")
            .find({ query: q1 });
          if (res1.total == 1 && this.user.mhf_pass_signature == null) {
            let mhf_id = res1.data[0].mhf_id;

            let dataSignatureForm1 = {
              mhf_pass_singnature: resSignature.data.Url,
              mhf_pass_sign_date: new Date(),
            };
            await feathersClientUOA
              .service("mubadala-health-form-2")
              .patch(mhf_id, dataSignatureForm1);
          }

          let q2 = {};
          q2.p_id = this.idPass;
          let res2 = await feathersClientUOA
            .service("mubadala-covid-form-2")
            .find({ query: q2 });
          if (res2.total == 1  && this.user.mcf_pass_signature == null) {
            let mcf_id = res2.data[0].mcf_id;
            let dataSignatureForm2 = {
              mcf_pass_signature: resSignature.data.Url,
              mcf_pass_sign_date: new Date(),
            };
            await feathersClientUOA
              .service("mubadala-covid-form-2")
              .patch(mcf_id, dataSignatureForm2);
          }

          let q3 = {};
          q3.p_id = this.idPass;
          let res3 = await feathersClientUOA
            .service("mubadala-alcohol-form-2")
            .find({ query: q3 });
          if (res3.total == 1  && this.user.maf_pass_signature == null) {
            let maf_id = res3.data[0].maf_id;
            let dataSignatureForm3 = {
              maf_pass_signature: resSignature.data.Url,
              maf_pass_sign_date: new Date(),
            };
            await feathersClientUOA
              .service("mubadala-alcohol-form-2")
              .patch(maf_id, dataSignatureForm3);
          }
          this.dialogSaveComplete = true;
        } catch (error) {
          console.log(error);
        }
      }else{
         this.dialogWarningSign = true;
      }
    },

    ClosePage() {
      this.dialogSaveComplete = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
}

.t-company {
  font-size: 12px;
}
</style>>
